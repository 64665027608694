<template>
  <div class="cart">

    <NavHeader />

    <div class="content_container content">
      <div class="">
        <h1>
          {{info.notice_title}}
        </h1>
        <dl>
          <dt>{{info.notice_author}}</dt>
          <dd>{{info.notice_addtime}}</dd>
        </dl>
        <div v-html='info.notice_content' style='padding:30px'>
          
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return { info: {} };
  },
  computed: {},
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$get("home/noticetwo/" + this.$route.query.id).then(res => {
        this.info = res;
      });
    }
  },
  components: {
    NavHeader
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.content {
  margin-top: 10px !important;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  min-height: 600px;

  h1 {
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  dl {
    @include space-between;

    padding: 0 10px;
    text-align: center;
    color: #999;
    dt {
      width: 50%;
     

      text-align: center;
    }
    dd {
      text-align: center;
      width: 50%;
      
    }
  }
  .cont {
  }
}
</style>
 